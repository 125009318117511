function Barsingle({index,data,year}){
    
    let total = data.operating + data.acquired + data.closed
    let success = ((data.operating/total)*100).toFixed()    
    let failure = ((data.closed/total)*100).toFixed()
    let acquire = ((data.acquired/total)*100).toFixed()

    return (
        <div className="bar-group">
    <div className={`barplot bar-${index} stat-1`} style={{height:`${failure}%`}}>      
      <span>{failure}</span>
    </div>
    <div className={`barplot bar-${index} stat-2`} style={{height: `${success}%`}}>
      <span>{success}</span>
    </div>
    <div className={`barplot bar-${index} stat-3`} style={{height: `${acquire}%`}}>
      <span>{acquire}</span>
    </div>
  </div>
    )
}

export default Barsingle;