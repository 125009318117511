import Barsingle from "./Barsingle";

function Barplot({data}){
  var numbers = [100]
  var years = [2010]
  if(Object.keys(data).length>0){
    numbers.pop()
    years.pop()
    for (const [key, value] of Object.entries(data)) {
      numbers.push(Number(value['operating']),Number(value['acquired']),Number(value['closed']))
      years.push(key)
    }
    numbers.sort(function(a, b) {return a - b;})
    numbers.reverse()
  }
    return(
      <div className="row">
        <div className="col-md-12 mb-3">
         <div className="card h-100">
            <div className="card-body">
               <div className="d-flex mb-3 heading_">
                  <div className="flex-grow-1">
                     <h5 className="mb-1">Historical Market Analytics</h5>
                     <div className="fs-13px">5 yearly performance chart of similar market. This chart indicates how start-ups with similar market-type grew in a space of 5 years. The data used for this chart is extracted from start-ups with similar market-type.</div>
                  </div>
               </div>
               <div style={{textAlign:'center'}}>
               <span className="badge" style={{backgroundColor:'#79a502', marginRight:'5px'}}>Success</span>
               <span className="badge" style={{backgroundColor:'#0383a9', marginRight:'5px'}}>Acquire</span>
               <span className="badge" style={{backgroundColor:'#cf3a02'}}>Closed</span>
               </div>
                <div id="bar-chart">
                  <div className="graph">
                    <ul className="x-axis">
                      <li><span>{years[0]}</span></li>
                      <li><span>{years[1]}</span></li>
                      <li><span>{years[2]}</span></li>
                      <li><span>{years[3]}</span></li>
                      <li><span>{years[4]}</span></li>
                    </ul>
                    <ul className="y-axis">
                      <li><span>{parseInt(numbers[0].toFixed())}</span></li>
                      <li><span>{parseInt(numbers[0] - ((numbers[0]/2)/2).toFixed())}</span></li>
                      <li><span>{parseInt((numbers[0]/2).toFixed())}</span></li>
                      <li><span>{parseInt(((numbers[0]/2)/2).toFixed())}</span></li>
                      <li><span>0</span></li>
                    </ul>
                    <div className="barplots">
                        {Object.keys(data).map((key,index) => 
                            <Barsingle key={index} index={index} data={data[key]} year={key} />
                        )}
                    </div>
                  </div>
                </div>
              
            </div>
         </div>
      </div>
      </div>
    )
}


export default Barplot;