import React, { useEffect, useState } from 'react';
import axios from "axios";
import Swal from 'sweetalert2'
import {plotDonut, donut_large} from './components/includes';
import Donut from './components/Donut';
import Barplot from './components/Bar';
import Tweet from './components/Tweet';
import Formsearch from './components/Formsearch';
import Profile from './components/Profile';
import Suggestions from './components/Suggestions';
import Analytics from './components/Analytics';
import Ratings from './components/Ratings';
import Qrcode from './components/Qrcode';
import Footer from './components/Footer';

var tempmem ={data:{bardata:{}, donutdata:{}}}

function closePreferences(e=false){
  if(e)e.preventDefault()
  document.querySelector('#startupform').classList.toggle('closeSlider')
  document.querySelector('.preferences i').classList.toggle('fa-arrow-down-long')
  document.querySelector('.preferences i').classList.toggle('fa-close')
}

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

function moveElement(item) {
  let id = null;
  const elem = document.querySelector(item);
    if(window.screen.availWidth>768) {
      elem.classList.add('moving-div')
    } else {
      closePreferences()
    }
    setTimeout(()=>{
      elem.className = 'col-md-4'
      elem.style.position = 'unset'
      elem.style.animation = 'unset'
      document.querySelector('#donutsection').classList.add('fading-div')
      document.querySelector('#donutsection').style.display = 'block'
      // document.querySelector('#content h1.page-header').classList.remove('text-center')
      document.querySelector('#content h1.page-header').style.display = 'none'
    }, 400)
}

function swtalert(type, text){
  if(type=='error'){
    Swal.fire({
      title: 'Error!',
      text: text,
      icon: type,
      confirmButtonText: 'ok'
    })
  } else {
    Swal.fire({
      title: 'Success!',
      text: text,
      icon: type,
      confirmButtonText: 'ok'
    })
  }
}

function button_loading(target, status){
  if(status=='loading'){
    let loading_ = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...`
    target.disabled = true
    target.innerHTML = loading_
  } else {
    target.disabled = false
    target.innerHTML = 'Proceed'
  }
}

async function SubmitForm(){
  var status = false
  var formbutton = document.querySelector('#startupform button')
  const form = document.querySelector('#startupform');
  const formData = new FormData(form);
  var formOutput = {}
  for (const [key, value] of formData) {
    formOutput[key] = value
  }
  
  // check for errors in form before passing to server
  if(formOutput.key==""){
    swtalert('error', 'Please enter valid search KEY')
    return status;
  } else {
    // start button loading
    button_loading(formbutton,'loading')
    //fetch data

  let getdata;
  
//   http://127.0.0.1:3100/api/search
  await axios.post('https://z-api.webprojectdev.com/api/search', formOutput)
                    .then((d)=>{
                      getdata = d.data
                    })
  try{
      tempmem.data = getdata.data
    //   console.log(getdata.data)
    
      let dvalues = tempmem.data.donutdata.values

      // animate
      moveElement('#fomsection')
      // arrange values and get ready for plotting big doughnut
      let obj = [dvalues.success,dvalues.failure,dvalues.acquire]
      obj.sort().reverse()
      let maxName = getKeyByValue(dvalues,obj[0])
      let secondmaxName = getKeyByValue(dvalues,obj[1])
      if (maxName === 'failure'){
        maxName = getKeyByValue(dvalues,obj[1])
        secondmaxName = getKeyByValue(dvalues,obj[2])
      }
      if (secondmaxName === 'failure'){
          secondmaxName = getKeyByValue(dvalues,obj[2])
      }

      let max = dvalues[maxName]
      let max2 = dvalues[secondmaxName]

      document.querySelector('.mypiechart').style.display = 'block'
      plotDonut(dvalues)
      donut_large(max2, 100-max2, 5.5, 0, '.'+secondmaxName)
      donut_large(max, 100-max, 7.5, 100-max2,'.'+maxName)
      
      button_loading(formbutton,'stop')
      document.querySelector('#app').style.paddingTop = 0
      document.querySelector('#startupform').style.overflowY = 'hidden'
      setTimeout(()=>{
        document.querySelector('#profileSection').style.height = "216px";
      },1000)

      status = true
      return status;
    } catch(err){
      button_loading(formbutton,'stop')
      swtalert('error', getdata.data.msg)
    }
  }
  
}

setTimeout(() => {
    const url = window.location.href.split('q')
    if(url[1]){
        let url2 = url[1].split('#')
        const searchkey = url2[0].substring(1)
        if(searchkey.length>5){
            document.querySelector("form button").click();
        }
    }
}, 500);

function Search() {

  function clickInfo(){
    // document.querySelector('#barPlotArea').style.display = 'block'
    // document.querySelector('#tweetsArea').style.display = 'flex'
    document.querySelectorAll('.display_on_reult').forEach((item, index, arr)=>{
      arr[index].style.display = 'block'
    })
    // window.scrollTo(0,document.body.scrollHeight);
    window.scrollTo(0,700);
  }

  const [ipLocation, setIPLoc] = useState('');
  const [data, setData] = useState(tempmem);

  useEffect(() => {
    tempmem = data
  },[data])

  useEffect(() => {
    // setTimeout(() => {
      document.querySelector('.logocontainer').style.display = 'none';
    // }, 6500);   
  },[])

    return (
      <>
        <div id="app" className="app">

<div id="content" className="app-content">
   <h1 className="page-header mb-3 text-center">
      Hello, <small>welcome back.</small>
   </h1>
   <div className="row">
    
    <Profile data={tempmem.data.profile} />

    <Formsearch submitForm={SubmitForm} setData={setData} closePreferences={closePreferences} />
     
    <Donut data={tempmem.data.donutdata} clickInfo={clickInfo} getKeyByValue={getKeyByValue} />

   </div>

   <div className="display_on_reult" style={{display: "none"}}>
      <div className='row'>
        <Tweet data={tempmem.data.twt_data} />
        <Ratings data={tempmem.data.ratings} />
      </div>

      <Suggestions data={tempmem.data.recommend} />

      <Analytics data={tempmem.data.keywordsAnalytics} />

      <Barplot data={tempmem.data.bardata} />
        
      <Qrcode searchkey={tempmem.data.shortLink} profile={tempmem.data.profile} />

      <Footer />

   </div>


   
</div>
</div>
      </>
    );
}

export default Search;