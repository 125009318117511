import facebook from '../assets/facebook.png';
import semrush from '../assets/semrush.png';
import googleAnalytics from '../assets/googleAnalytics.png';

function Footer(){
    return(
        <div className="row">
        <div className='col-md-12'>
          <div className='card'>
            <div className='card-body'>
              <h4>Future upgrades</h4>
              <small>Possible new features that can be added</small>
              <div className='p-4 text-center'>
                <div className="img-sect">
                  <img src={googleAnalytics} />
                  <img src={facebook} />
                  <img src={semrush} />
                </div>

                <p className='mt-3'>Future upgrage will include gathering web interlinked data using <strong>Google Analytics</strong> API. Also, more social media data to firmly verify startup online presence and legitimacy will be increased using <strong>Facebook</strong> API.</p>
                <p><strong>Semrush</strong> API will also be used to collect granular data related to keyword search quries and similarities that could increase possible ranking for the startup Web/App (if any).</p>
              </div>
            </div>
          </div>      
        </div>
      </div>
    )
}

export default Footer;