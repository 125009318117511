import trustpilotLogo from '../assets/trustpilot.png'

function Ratings({data}){
    if(data){
        const total_val = data.reduce((partialSum, a) => partialSum + a, 0);
        const excellent = (data[0]/total_val)*100
        const great = (data[1]/total_val)*100
        const average = (data[2]/total_val)*100
        const poor = (data[3]/total_val)*100
        const bad = (data[4]/total_val)*100
        return (
            <>
            <div className="col-md-7 mb-3">
                <div className="card h-100">
                    <div className="card-body">
                    <div className="d-flex mb-3">
                        <div className="flex-grow-1">
                            <h5 className="mb-1">Trustpilot ratings</h5>
                            <div className="fs-13px">Ratings and review of URL from Trustpilot</div>
                        </div>
                        <img src={trustpilotLogo} style={{width:'112px'}} />
                    </div>
                    <div className="mb-4">


                    <div className='row'>
                        <div className='col-4'>Excellent</div>
                        <div className='col-6 mt-1 p-0'>
                        <div className="progress">
                            <div className="progress-bar bg-trustpilot" style={{width: `${excellent.toFixed()}%`}}></div>
                        </div>
                        </div>
                        <div className='col-2 text-center'>{excellent.toFixed()}%</div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-4'>Great</div>
                        <div className='col-6 mt-1 p-0'>
                        <div className="progress">
                            <div className="progress-bar bg-trustpilot" style={{width: `${great.toFixed()}%`}}></div>
                        </div>
                        </div>
                        <div className='col-2 text-center'>{great.toFixed()}%</div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-4'>Average</div>
                        <div className='col-6 mt-1 p-0'>
                        <div className="progress">
                            <div className="progress-bar bg-trustpilot" style={{width: `${average.toFixed()}%`}}></div>
                        </div>
                        </div>
                        <div className='col-2 text-center'>{average.toFixed()}%</div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-4'>Poor</div>
                        <div className='col-6 mt-1 p-0'>
                        <div className="progress">
                            <div className="progress-bar bg-trustpilot" style={{width: `${poor.toFixed()}%`}}></div>
                        </div>
                        </div>
                        <div className='col-2 text-center'>{poor.toFixed()}%</div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-4'>Bad</div>
                        <div className='col-6 mt-1 p-0'>
                        <div className="progress">
                            <div className="progress-bar bg-warning" style={{width: `${bad.toFixed()}%`}}></div>
                        </div>
                        </div>
                        <div className='col-2 text-center'>{bad.toFixed()}%</div>
                    </div>


                    </div>
                    </div>
                </div>
            </div>
            </>
        )
    } else {
        return (
            <></>
        )
    }
}

export default Ratings;