
function Profile({data}){
    if(data){
    return (
        <>
        <div className="profile slideMeDown" id="profileSection">
        <div className="profile-header">
            <div className="profile-header-cover" style={{height:'7rem', backgroundImage:`url(${data.banner})`}}></div>
            <div className="profile-header-content">
                <div className="profile-header-img" style={{width:'6rem', height:'6rem', position:'relative', 
                borderRadius:'50%', top: '-25px'}}>
                    <img src={data.img} alt="" />
                </div>
                <ul className="profile-header-tab nav nav-tabs nav-tabs-v2" role="tablist">
                    <li className="nav-item" role="presentation">
                    <a href="#profile-friends" className="nav-link" data-bs-toggle="tab" aria-selected="true" role="tab">
                        <div className="nav-field">Friends</div>
                        <div className="nav-value">{Number((data.friends).toFixed(1)).toLocaleString()}</div>
                    </a>
                    </li>
                    <li className="nav-item" role="presentation">
                    <a href="#profile-followers" className="nav-link" data-bs-toggle="tab" aria-selected="false" tabIndex="-1" role="tab">
                        <div className="nav-field">Followers</div>
                        <div className="nav-value">{Number((data.followers).toFixed(1)).toLocaleString()}</div>
                    </a>
                    </li>
                    <li className="nav-item" role="presentation">
                    <a href="#profile-listed" className="nav-link" data-bs-toggle="tab" aria-selected="false" tabIndex="-1" role="tab">
                        <div className="nav-field">Listed</div>
                        <div className="nav-value">{Number((data.listed).toFixed(1)).toLocaleString()}</div>
                    </a>
                    </li>
                    <li className="nav-item" role="presentation">
                    <a href="#profile-favourited" className="nav-link" data-bs-toggle="tab" aria-selected="false" tabIndex="-1" role="tab">
                        <div className="nav-field">Fav</div>
                        <div className="nav-value">{Number((data.favourites).toFixed(1)).toLocaleString()}</div>
                    </a>
                    </li>
                    <li className="nav-item" role="presentation">
                    <a href="#profile-status" className="nav-link" data-bs-toggle="tab" aria-selected="false" tabIndex="-1" role="tab">
                        <div className="nav-field">Status</div>
                        <div className="nav-value">{Number((data.statuses).toFixed(1)).toLocaleString()}</div>
                    </a>
                    </li>
                </ul>
            </div>
        </div>
        <div className="profile-container pb-0" style={{position:'relative', float:'right', paddingTop:'10px', top:'-70px'}}>
            <div className="profile-sidebar" style={{width: '100%'}}>
                <div className="desktop-sticky-top" style={{float:'left'}}>
                    <h4>{data.displayname}</h4>
                    <div className="fw-600 text-muted mb-3 mt-n2">@{data.username}</div>
                    
                </div>
            </div>
        </div>
        </div>
        </>
    )
    } else {
        return (
            <></>
        )
    }
}


export default Profile;