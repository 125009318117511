
function Formsearch({submitForm,setData, closePreferences}){
    let url = window.location.href.split('q')
    let url2 = url[1].split('#')
    const searchkey = url2[0].substring(1)
    return(
        <>
        <div className="col-md-6 mx-auto" id="fomsection">
         <div className="card mb-3 flex-1">
            <div className="card-body">
               <div className="d-flex mb-3">
                    <div className="flex-grow-1">
                        <h5 className="mb-1">Preference form</h5>
                    </div>
                    <a href="#" className="text-muted preferences" onClick={(e)=>closePreferences(e)}>
                        <i className="fa fa-close"></i>
                    </a>
               </div>
                <form id="startupform" className="slider kliq-searchform">
                <label>Enter search KEY</label>
                <input type="text" name="key" className="form-control" defaultValue={searchkey} />

                <div className="mt-3">
                    <button type="button" className="btn btn-primary w-100" onClick={()=>{
                        submitForm().then((rt)=>{
                            if(rt){
                                setData({data:{bardata:{}, donutdata:{}}})
                            }
                        })
                        }}>Proceed</button>
                </div>
                </form>
                <div className="text-center kliq-qrcode">
                    <img src={`https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=${window.location.href}&choe=UTF-8`} />
                    <p>{searchkey}</p>
                </div>
            </div>
         </div>
      </div>
      </>
    )
}


export default Formsearch;