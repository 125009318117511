
function Remark({index, data, name}){
    const recommendations = {
        "Age": {'excellent':'', 'average': 'The age of the startup is young.', 'poor':'The age of the startup is too young. Consider building audience over time'},
        "Funding": {'excellent':'', 'average': 'The funding amount is small, but might appreciate over time', 'poor':'The funding amount so far is too poor and shows no one has indicated interest. Please check other section to see your level of online validity.'},
        "Likes": {'excellent':'', 'average': 'Likes for your startup is yet to grow as expected.', 'poor':'The likes is too poor. You need to work more on your customers.'},
        "Followers": {'excellent':'', 'average': 'Not much followers, but there is time to grow.', 'poor':'Followers count is too poor. Try growing audience via social influencers or other marketing methods.'},
        "Twitter age": {'excellent':'', 'average': 'Your Twitter age is young, but not too bad if you have good number of followers', 'poor':'Twitter age is too low. Your account is below expected years for this kind of business.'},
        "Friends": {'excellent':'', 'average': 'Seems like you don\'t have much friends.', 'poor':'If your number of frineds get any lower than this you could destroy your business.'},
        "Tweets": {'excellent':'', 'average': 'Minimum tweets required was attained.', 'poor':'Try and post more tweets to gain attention on Twitter.'},
        "URL": {'excellent':'', 'average': 'There is a website link but it does not work.', 'poor':'There is no website link where users can reach your business and know more about it.'},
        "Verified": {'excellent':'', 'average': 'Your account on twitter is verified', 'poor':'Your account on twitter is not verified.'}
    }
    return(
        <>
            <tr>
                <th scope="row">{name}</th>
                <td>{data.data.toLocaleString()}</td>
                <td>{data.expected.toLocaleString()}</td>
                <td>{
                        (()=>{
                            const remark = data.remark
                            if(remark=='Excellent'){
                                return <><i className="fa fa-check-circle text-teal"></i></>
                            }else if(remark=='Average'){
                                return <><i className="fa fa-exclamation-triangle text-warning"></i></>
                            } else {
                                return <><i className="fa fa-exclamation-circle text-danger"></i></>
                            }
                        })()
                    }
                </td>
                <td>{
                        (()=>{
                            const remark = data.remark
                            if(remark=='Excellent'){
                                return <>{remark}</>
                            }else if(remark=='Average'){
                                return <>{remark}</>
                            } else {
                                return <>{remark}</>
                            }
                        })()
                    }
                </td>
                <td>{recommendations[name][data.remark.toLowerCase()]}</td>
            </tr>
        </>
    )
}

export default Remark;