import Remark from "./Remark";

function Suggestions({data}){
    if(data){
        
    return (
        <div className="row">
        <div className="col-md-12 mb-3">
            <div className="card h-100">
                <div className="card-body">
                    <div className="d-flex mb-3 heading_">
                        <div className="flex-grow-1">
                            <h5 className="mb-1">Suggestions</h5>
                            <div className="fs-13px">Making suggestions according to the start-up funding and sentimental data. The start-up has:</div>
                        </div>
                    </div>
                    <div className="suggestSection table-responsive">
                        <table className="table table-striped mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Type</th>
                                    <th scope="col">Data</th>
                                    <th scope="col">Expected</th>
                                    <th scope="col"></th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Remark_and_recommendations</th>
                                </tr>
                            </thead>
                            <tbody>
                            {Object.keys(data).map((key,index) => 
                                <Remark key={index} index={index} data={data[key]} name={key} />
                            )}
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
    } else {
        return(
            <></>
        )
    }
}

export default Suggestions;