
function Formdata({countryFlagHandler,ipLocation,submitForm,setData, closePreferences}){
    
    return(
        <>
        <div className="col-md-6 mx-auto" id="fomsection">
         <div className="card mb-3 flex-1">
            <div className="card-body">
               <div className="d-flex mb-3">
                    <div className="flex-grow-1">
                        <h5 className="mb-1">Preference form</h5>
                    </div>
                    <a href="#" className="text-muted preferences" style={{textDecoration:'none'}} onClick={(e)=>closePreferences(e)}>
                        <span>close </span> <i className="fa fa-close" style={{position:'relative', top:'2px'}}></i>
                    </a>
               </div>
                <form id="startupform" className="slider">
                <label>Enter startup twitter name</label>
                <input type="text" name="name" className="form-control" />

                <label className="mt-2">Select country <img id="country_img" src='' width="20px"/></label>
                <select className="form-select" name="country" 
                        onChange={(e)=>countryFlagHandler(e.target.value)} 
                        defaultValue={ipLocation}>
                    <option>AUS</option>
                    <option>BRA</option>
                    <option>CAN</option>
                    <option>CHL</option>
                    <option>CHN</option>
                    <option>DEU</option>
                    <option>ESP</option>
                    <option>FRA</option>
                    <option>GBR</option>
                    <option>IND</option>
                    <option>ISR</option>
                    <option>JPN</option>
                    <option>KOR</option>
                    <option>NLD</option>
                    <option>SGP</option>
                    <option>SWE</option>
                    <option>USA</option>
                    <option>Others</option>
                </select>

                <div className="row g-2 mt-2">
                    <div className="col-6">
                        <label>Select category</label>
                        <select className="form-control form-select" name="category">
                            <option>ADVERTISING</option>
                            <option>ANALYTICS</option>
                            <option>BIOTECHNOLOGY</option>
                            <option>CLEAN TECHNOLOGY</option>
                            <option>CURATED WEB</option>
                            <option>E-COMMERCE</option>
                            <option>EDUCATION</option>
                            <option>ENTERPRISE SOFTWARE</option>
                            <option>FINANCE</option>
                            <option>GAMES</option>
                            <option>HARDWARE + SOFTWARE</option>
                            <option>HEALTH AND WELLNESS</option>
                            <option>HEALTH CARE</option>
                            <option>MANUFACTURING</option>
                            <option>MOBILE</option>
                            <option>SECURITY</option>
                            <option>SOCIAL MEDIA</option>
                            <option>SOFTWARE</option>
                            <option>OTHERS</option>
                        </select>
                    </div>
                    <div className="col-6">
                        <label>Funding type</label>
                        <select name="funding_type" className="form-control form-select">
                            <option>ANGEL</option>
                            <option>CONVERTIBLE NOTE</option>
                            <option>DEBT FINANCING</option>
                            <option>GRANT</option>
                            <option>POST IPO DEBT</option>
                            <option>POST IPO EQUITY</option>
                            <option>PRIVATE EQUITY</option>
                            <option>PRODUCT CROWDFUNDING</option>
                            <option>ROUNDS</option>
                            <option>SECONDARY MARKET</option>
                            <option>SEED</option>
                            <option>UNDISCLOSED</option>
                            <option>VENTURE</option>
                        </select>
                    </div>
                </div>

                <div className="row g-2 mt-2">
                    <div className="col-6">
                        <label>Found date</label>
                        <input type="date" className="form-control" name="startdate" />
                    </div>
                    <div className="col-6">
                        <label>Last funding date</label>
                        <input type="date" className="form-control" name="first_funding" />
                    </div>
                </div>

                

                

                <label className="mt-2">Total funding</label>
                <div className="input-group">
                    <input type="number" className="form-control" name="funding_total_usd" placeholder="0.00" />
                    <label className="input-group-text">
                    USD
                    </label>
                </div>

                <div className="mt-3">
                    <button type="button" className="btn btn-primary w-100" onClick={()=>{
                        submitForm().then((rt)=>{
                            if(rt){
                                setData({data:{bardata:{}, donutdata:{}}})
                            }
                        })
                        }}>Proceed</button>
                </div>
                </form>
            </div>
         </div>
      </div>
      </>
    )
}


export default Formdata;