// import './tweetplot.css';
import twtLogo from '../assets/twtLogo.jpg'

function Tweet({data}){
  if(data){
    const total_val = data.positive + data.negative + data.neutral
    const neu = (data.neutral/total_val)*100
    const pos = (data.positive/total_val)*100
    const neg = (data.negative/total_val)*100
    const diff = pos - neg
    
    if(diff>0){
      var color = 'success'
      var fa_icon = 'fa-check-circle'
    } else {
      var color = 'danger'
      var fa_icon = 'fa-exclamation-triangle'
    }
  return(
      <div className="col-md-5 mb-3">
        <div className="card h-100">
            <div className="card-body">
              <div className="d-flex mb-3">
                  <div className="flex-grow-1">
                    <h5 className="mb-1">Twitter Sentiment</h5>
                    <div className="fs-13px">Polarized tweets from twitter</div>
                  </div>
                  <img src={twtLogo} style={{width:'45px'}} />
              </div>
              <div className="mb-4">
                  <h3 className="mb-1">{data.total_tweets} <span className="fs-10px text-muted">Tweet Data found</span></h3>
                  <div className={`text-${color} fs-13px fw-600`}>
                    <i className={`fa ${fa_icon}`}></i> {diff.toFixed()}% <span className="text-muted"> (polarity difference)</span>
                  </div>
              </div>

              <div className='row'>
                <div className='col-4'>Neutral</div>
                <div className='col-6 mt-1 p-0'>
                  <div className="progress">
                    <div className="progress-bar" style={{width: `${neu.toFixed()}%`}}></div>
                  </div>
                </div>
                <div className='col-2 text-center'>{neu.toFixed()}%</div>
              </div>
              <div className='row mt-2'>
                <div className='col-4'>Positive</div>
                <div className='col-6 mt-1 p-0'>
                  <div className="progress">
                    <div className="progress-bar bg-teal" style={{width: `${pos.toFixed()}%`}}></div>
                  </div>
                </div>
                <div className='col-2 text-center'>{pos.toFixed()}%</div>
              </div>
              <div className='row mt-2'>
                <div className='col-4'>Negative</div>
                <div className='col-6 mt-1 p-0'>
                  <div className="progress">
                    <div className="progress-bar bg-danger" style={{width: `${neg.toFixed()}%`}}></div>
                  </div>
                </div>
                <div className='col-2 text-center'>{neg.toFixed()}%</div>
              </div>
            </div>
        </div>
      </div>
  )
  } else {
    return (
      <></>
    )
  }
}

export default Tweet;