

function Donut({data, clickInfo, getKeyByValue}){
  var max, maxName;
  if(data.values != undefined){
    let arr = Object.values(data.values);
    max = Math.max(...arr);
    maxName = getKeyByValue(data.values,max)
  }


    return (
        <div className="col-md-8 mb-3" id="donutsection" style={{display:'none'}}>
         <div className="card h-100">
            <div className="card-body">
               <div className="d-flex mb-3">
                  <div className="flex-grow-1">
                     <h5 className="mb-1">Startup Analytics</h5>
                     <div className="fs-13px">Predicted performance chart</div>
                  </div>
               </div>
               <div className="mypiechart" style={{display:'none'}}>	
                  <section className="text-center mb-2">
                    <div className="svg-item">
                      <svg width="100%" height="100%" viewBox="0 0 40 40" className="donut">
                        <circle className="donut-hole" cx="20" cy="20" r="14" fill="#fff"></circle>
                        <circle className="donut-ring" cx="20" cy="20" r="14"></circle>
                        <circle className="donut-segment donut-segment-2 success" cx="20" cy="20" r="14"></circle>
                        <circle className="donut-segment donut-segment-3 acquire" cx="20" cy="20" r="14"></circle>

                        <g className="donut-text donut-text-1">
                          <text y="50%" transform="translate(0, 2)">
                            <tspan x="50%" textAnchor="middle" className="donut-percent">{max ? max.toFixed() : ''}%</tspan>   
                          </text>
                          <text y="60%" transform="translate(0, 2)">
                            <tspan x="50%" textAnchor="middle" className="donut-data">{maxName ? maxName : ''}</tspan>   
                          </text>
                        </g>

                      </svg>
                    </div>
                  </section>

                  <section>
                    <ul className="display-container">
                      <li className="note-display" data-note="success">
                        <div className="circle">
                          <svg width="84" height="84" className="circle__svg">
                            <circle cx="41" cy="41" r="38" className="circle__progress circle__progress--path"></circle>
                            <circle cx="41" cy="41" r="38" className="circle__progress circle__progress--fill"></circle>
                          </svg>

                          <div className="percent">
                            <span className="percent__int">0.</span>
                            <span className="percent__dec">00</span>
                          </div>
                        </div>

                        <span className="label">Operating</span>
                      </li>

                      <li className="note-display" data-note="acquire">
                        <div className="circle">
                          <svg width="84" height="84" className="circle__svg">
                            <circle cx="41" cy="41" r="38" className="circle__progress circle__progress--path"></circle>
                            <circle cx="41" cy="41" r="38" className="circle__progress circle__progress--fill"></circle>
                          </svg>

                          <div className="percent">
                            <span className="percent__int">0.</span>
                            <span className="percent__dec">00</span>
                          </div>
                        </div>

                        <span className="label">Acquired</span>
                      </li>

                      <li className="note-display" data-note="failure">
                        <div className="circle">
                          <svg width="84" height="84" className="circle__svg">
                            <circle cx="41" cy="41" r="38" className="circle__progress circle__progress--path"></circle>
                            <circle cx="41" cy="41" r="38" className="circle__progress circle__progress--fill"></circle>
                          </svg>

                          <div className="percent">
                            <span className="percent__int">0.</span>
                            <span className="percent__dec">00</span>
                          </div>
                        </div>

                        <span className="label">Closed</span>
                      </li>
                    </ul>
                  </section>

                  <section>
                    <p className='pt-4 text-center'><em>{data ? data.msg : ""}</em> <button type="button" className="btn btn-link" onClick={()=>clickInfo()}>Click here for more</button></p>
                  </section>
                </div>
            </div>
         </div>
      </div>
    )
}


export default Donut;