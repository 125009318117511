function plotDonut(dvalues){
    const displays = document.querySelectorAll('.note-display');
    const transitionDuration = 1500;
  
    displays.forEach(display => {
      let numtostr = dvalues[display.dataset.note].toString()
      let note = parseFloat(dvalues[display.dataset.note]);
      let [int, dec] = numtostr.split('.');
      [int, dec] = [Number(int), Number(dec)];
  
      strokeTransition(display, note);
  
      increaseNumber(display, int, 'int');
      if (dec>0){increaseNumber(display, dec, 'dec')};
    });
  
    function strokeTransition(display, note) {
      let progress = display.querySelector('.circle__progress--fill');
      let radius = progress.r.baseVal.value;
      let circumference = 2 * Math.PI * radius;
      let offset = circumference * (100 - note) / 100;
  
      progress.style.setProperty('--initialStroke', circumference);
      progress.style.setProperty('--transitionDuration', `${transitionDuration}ms`);
  
      setTimeout(() => progress.style.strokeDashoffset = offset, 100);
    }
  
    function increaseNumber(display, number, className) {
      let element = display.querySelector(`.percent__${className}`),
          decPoint = className === 'int' ? '.' : '',
          interval = transitionDuration / number,
          counter = 0;
  
      let increaseInterval = setInterval(() => {
        if (counter === number) { window.clearInterval(increaseInterval); }
  
        element.textContent = counter + decPoint;
        counter++;
      }, interval);
    }
  }

  function donut_large(filled, notFilled, width, start, sel){
    let sect = document.querySelector(sel);
    
    sect.style.strokeWidth = width;
    sect.style.strokeDasharray = filled+' '+notFilled;
    sect.style.strokeDashoffset = start;
  }


  export {plotDonut, donut_large};