import Chart from "react-apexcharts";

function Analytics({data}){

    if(data){
        const country_trend = Object.entries(data.country_trend)
        .sort(([,a],[,b]) => a-b)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        // country trend
        const data_val = Object.values(country_trend);
        const categories = Object.keys(country_trend);
        // people's interest with time
        const trend_val = Object.values(data.trend);
        const trend_dates = Object.keys(data.trend);
        
        // country trend
    const hor = {
        series: [{
            name: 'Keyword search',
            data: data_val
        }],
        options: {
          chart: {
            type: 'bar',
            height: 350,
            toolbar:{
                show:false
            }
          },
          fill:{
            pattern: {
                style: 'verticalLines',
                width: 6,
                height: 6,
                strokeWidth: 1,
            },
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: true
          },
          xaxis: {
            categories: categories,
          }
        },
      
      
      };

    // trend chart
    const areachart = {
        options: {
          chart: {
            type: "area",
            toolbar: {
                show: false
            }
          },
          labels: trend_dates,
          xaxis: {
            type: 'datetime'
          },
        },
        series: [
          {
            name: "Trend",
            data: trend_val
          }
        ]
      }

    //   related data
    function create_donut(num){
    const relate = {
        series: [num],
        options: {
            chart: {
            type: 'radialBar',
            width: 50,
            height: 50,
            sparkline: {
                enabled: true
            }
            },
            colors: ['#ff925e'],
            dataLabels: {
            enabled: false
            },
            plotOptions: {
            radialBar: {
                hollow: {
                margin: 0,
                size: '50%'
                },
                track: {
                margin: 0
                },
                dataLabels: {
                show: false
                }
            }
            }
        }
    }

    return <Chart options={relate.options} series={relate.series} type="radialBar" height={35} width={35} />
    }

    //   related keyword radial
    function create_keyword_radar(radar_data){
    const keywordradial = {
          
        series: [{
          name: 'keywords',
          data: Object.values(radar_data)
        }],
        options: {
          chart: {
            height: 350,
            type: 'radar',
            toolbar: {
                show: false
            }
          },
          dataLabels: {
            enabled: false
          },
          plotOptions: {
            radar: {
              size: 140,
              polygons: {
                strokeColors: '#e9e9e9',
                fill: {
                  colors: ['#f8f8f8', '#fff']
                }
              }
            }
          },
          colors: ['#FF4560'],
          markers: {
            size: 4,
            colors: ['#fff'],
            strokeColor: '#FF4560',
            strokeWidth: 2,
          },
          tooltip: {
            y: {
              formatter: function(val) {
                return val
              }
            }
          },
          xaxis: {
            categories: Object.keys(radar_data)
          },
          yaxis: {
            tickAmount: 7,
            labels: {
              formatter: function(val, i) {
                if (i % 2 === 0) {
                  return val
                } else {
                  return ''
                }
              }
            }
          }
        },
      
      
      };

      return <Chart options={keywordradial.options} series={keywordradial.series} type="radar" height={350} />
    }

    // keyword stress
    const keystress = {
          
        series: [76],
        options: {
          chart: {
            type: 'radialBar',
            offsetY: -20,
            sparkline: {
              enabled: true
            },
            toolbar: {
                show: false
            }
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: "#e7e7e7",
                strokeWidth: '97%',
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: '#999',
                  opacity: 1,
                  blur: 2
                }
              },
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  offsetY: -2,
                  fontSize: '22px'
                }
              }
            }
          },
          grid: {
            padding: {
              top: -10
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'light',
              shadeIntensity: 0.4,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 53, 91]
            },
          },
          labels: ['Average Results'],
        },
      
      
      };
    return (
        <div className="row">
            <div className="col-md-6 mb-3">
                <div className="card h-100">
                    <div className="card-body">
                        <div className="d-flex mb-3 heading_">
                            <div className="flex-grow-1">
                                <h5 className="mb-1">Trend</h5>
                                <div className="fs-13px">Keyword trend over the years.</div>
                            </div>
                        </div>
                        <Chart options={areachart.options} series={areachart.series} type="line" height={350} />
                    </div>
                </div>
            </div>
            <div className="col-md-6 mb-3">
                <div className="card h-100">
                    <div className="card-body">
                        <div className="d-flex mb-3 heading_">
                            <div className="flex-grow-1">
                                <h5 className="mb-1">Top 10 Countries</h5>
                                <div className="fs-13px">Countries where the keyword is frequently searched.</div>
                            </div>
                        </div>
                        <Chart options={hor.options} series={hor.series} type="bar" height={350} width="380" />
                    </div>
                </div>
            </div>
            <div className="col-md-6 mb-3">
                <div className="card h-100">
                    <div className="card-body">
                        <div className="d-flex mb-3 heading_">
                            <div className="flex-grow-1">
                                <h5 className="mb-1">Related Keywords</h5>
                                <div className="fs-13px">Keywords that are related to the startup name.</div>
                            </div>
                        </div>
                        {/* <Chart options={keystress.options} series={keystress.series} type="radialBar" /> */}
                        
                        {create_keyword_radar(data.related_keywords)}
        


                    </div>
                </div>
            </div>
            <div className="col-md-6 mb-3">
                <div className="card h-100">
                    <div className="card-body">
                        <div className="d-flex mb-3 heading_">
                            <div className="flex-grow-1">
                                <h5 className="mb-1">Rising Keywords</h5>
                                <div className="fs-13px">Keywords that are gaining trend with the startup name.</div>
                            </div>
                        </div>
                                {
                                    // Object.keys(data.related_keywords).map((key,index)=>{
                                    //     return (
                                    //         <tr key={key}>
                                    //             <td className="ps-0">{index}</td>
                                    //             <td>
                                    //             {key}
                                    //             </td>
                                    //             <td className="text-center pt-1">
                                    //                 {create_donut(data.related_keywords[key])}
                                    //             </td>
                                    //             <td className="text-end pe-0">{data.related_keywords[key]}</td>
                                    //         </tr>
                                            
                                    //         )
                                    // })
                                }

                                {
                                    create_keyword_radar(data.rising_keywords)
                                }
                    </div>
                </div>
            </div>
        </div>
    )
    } else {
        return (
            <></>
        )
    }
}

export default Analytics;