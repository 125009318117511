import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
import Search from './Search';



function App() {
  return (
    <Router>
        <Routes>
            <Route exact path='/' element={<Home />}></Route>
            <Route exact path='/search' element={<Search />}></Route>
        </Routes>
    </Router>
  );
}

export default App;
