import React, { useEffect, useState } from 'react';
import axios from "axios";
import Swal from 'sweetalert2'
import {plotDonut, donut_large} from './components/includes';
import Donut from './components/Donut';
import Barplot from './components/Bar';
import Tweet from './components/Tweet';
import Formdata from './components/Formdata';
import Profile from './components/Profile';
import Suggestions from './components/Suggestions';
import Analytics from './components/Analytics';
import Ratings from './components/Ratings';
import Qrcode from './components/Qrcode';
import Footer from './components/Footer';
import logo from './assets/logo192.png';

var tempmem ={data:{bardata:{}, donutdata:{}}}

function closePreferences(e=false){
  if(e)e.preventDefault()
  document.querySelector('#startupform').classList.toggle('closeSlider')
  // document.querySelector('.preferences i').classList.toggle('fa-arrow-down-long')
  document.querySelector('.preferences i').classList.toggle('fa-bars-staggered')
  document.querySelector('.preferences i').classList.toggle('fa-close')
  if(document.querySelector('.preferences i').classList.contains('fa-close')){
    document.querySelector('.preferences span').innerText = 'close'
  } else {
    document.querySelector('.preferences span').innerText = 'open'
  }
}

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

function moveElement(item) {
  let id = null;
  const elem = document.querySelector(item);
    if(window.screen.availWidth>768) {
      elem.classList.add('moving-div')
    } else {
      closePreferences()
    }
    setTimeout(()=>{
      elem.className = 'col-md-4'
      elem.style.position = 'unset'
      elem.style.animation = 'unset'
      document.querySelector('#donutsection').classList.add('fading-div')
      document.querySelector('#donutsection').style.display = 'block'
      // document.querySelector('#content h1.appTitle').classList.remove('text-center')
      document.querySelector('#content .appTitle').style.display = 'none'
    }, 400)
}

function swtalert(type, text){
  if(type=='error'){
    Swal.fire({
      title: 'Error!',
      text: text,
      icon: type,
      confirmButtonText: 'ok'
    })
  } else {
    Swal.fire({
      title: 'Success!',
      text: text,
      icon: type,
      confirmButtonText: 'ok'
    })
  }
}

function button_loading(target, status){
  if(status=='loading'){
    let loading_ = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...`
    target.disabled = true
    target.innerHTML = loading_
  } else {
    target.disabled = false
    target.innerHTML = 'Proceed'
  }
}

async function SubmitForm(){
  var status = false
  var formbutton = document.querySelector('#startupform button')
  const form = document.querySelector('#startupform');
  const formData = new FormData(form);
  var formOutput = {}
  for (const [key, value] of formData) {
    formOutput[key] = value
  }
  
  // check for errors in form before passing to server
  if(formOutput.name==""){
    swtalert('error', 'Startup name is invalid')
    return status;
  } else if(formOutput.startdate==""){
    swtalert('error', 'Fill the startup found date')
    return status;
  } else if(formOutput.funding_total_usd==""){
    swtalert('error', 'Fill the funding amount the startup has received so far')
    return status;
  } else if(formOutput.first_funding==""){
    swtalert('error', 'Fill the first funding date')
    return status;
  } else {
    // start button loading
    button_loading(formbutton,'loading')
    //fetch data
  let getdata;

  // http://127.0.0.1:3100/api/startup_prediction
  await axios.post('https://z-api.webprojectdev.com/api/startup_prediction', formOutput)
                    .then((d)=>{
                      getdata = d.data
                    })
  try{
      tempmem.data = getdata.data
      // console.log(getdata.data)
    
      let dvalues = tempmem.data.donutdata.values

      // animate
      moveElement('#fomsection')
      // arrange values and get ready for plotting big doughnut
      let obj = [dvalues.success,dvalues.failure,dvalues.acquire]
      obj.sort().reverse()
      let maxName = getKeyByValue(dvalues,obj[0])
      let secondmaxName = getKeyByValue(dvalues,obj[1])
      if (maxName === 'failure'){
        maxName = getKeyByValue(dvalues,obj[1])
        secondmaxName = getKeyByValue(dvalues,obj[2])
      }
      if (secondmaxName === 'failure'){
          secondmaxName = getKeyByValue(dvalues,obj[2])
      }

      let max = dvalues[maxName]
      let max2 = dvalues[secondmaxName]

      document.querySelector('.mypiechart').style.display = 'block'
      plotDonut(dvalues)
      donut_large(max2, 100-max2, 5.5, 0, '.'+secondmaxName)
      // donut_large(max, (100-max)+3, 7.5, 100-max2,'.'+maxName)
      donut_large(max, 100-max, 7.5, 100-max2,'.'+maxName)
      
      button_loading(formbutton,'stop')
      document.querySelector('#app').style.paddingTop = 0
      document.querySelector('#startupform').style.overflowY = 'hidden'
      setTimeout(()=>{
        document.querySelector('#profileSection').style.height = "216px";
      },1000)

      status = true
      return status;
    } catch(err){
      button_loading(formbutton,'stop')
      swtalert('error', getdata.data.msg)
    }
  }
  
}


//////////////////////////////////////////////////////
///////////////////// PWA install app ///////////////
////////////////////////////////////////////////////
let deferredPrompt;

window.addEventListener('beforeinstallprompt', (e) => {
    deferredPrompt = e;
});

async function installApp(){
    if (deferredPrompt !== null) {
      try{
        deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;
        if (outcome === 'accepted') {
            deferredPrompt = null;
            hideInstallPromotion();
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'You installed ZipLink AI',
              showConfirmButton: false,
              timer: 3000
            })
        }
      } catch(err){
        hideInstallPromotion();
        console.log(err)
      }
    }
};

// detect that app has been installed
window.addEventListener('DOMContentLoaded', function(){
  if (navigator.standalone || window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: minimal-ui)').matches) {
    // installed = true;
    setTimeout(()=>{hideInstallPromotion()},2000)
   }
});

// window.addEventListener('appinstalled', () => {
//   // Hide the app-provided install promotion
//   hideInstallPromotion();
//   // Clear the deferredPrompt so it can be garbage collected
//   deferredPrompt = null;
//   // Optionally, send analytics event to indicate successful install
//   console.log('PWA was installed');
// });

function hideInstallPromotion(){
  document.querySelector('.appinstallation').innerHTML = ''
}
////////////////////////////////////////////////

function Home() {

  function clickInfo(){
    // document.querySelector('#barPlotArea').style.display = 'block'
    // document.querySelector('#tweetsArea').style.display = 'flex'
    document.querySelectorAll('.display_on_reult').forEach((item, index, arr)=>{
      arr[index].style.display = 'block'
    })
    // window.scrollTo(0,document.body.scrollHeight);
    window.scrollTo(0,700);
  }

  const [ipLocation, setIPLoc] = useState('');
  const [data, setData] = useState(tempmem);

  useEffect(() => {
    tempmem = data
  },[data])

  useEffect(() => {
    getIPLocation() 
    setTimeout(() => {
      document.querySelector('.logocontainer').style.display = 'none';
    }, 6500);   
  },[])

  const getIPLocation = async () => {
    let data = await axios.get("https://api.db-ip.com/v2/free/self");
    let countryCode = data.data.countryCode
   setIPLoc(countryCode);
   countryFlagHandler(countryCode)
  //  the countrycode here is 2 letters, but you need 3 letters like i have did bellow
  let setw = document.querySelector('select[name=country]')
  for(var i=0;i<=setw.options.length;i++){
    if(setw[i].text.includes(countryCode)){
      document.querySelector('select[name="country"]').value = setw[i].text;
      break;
    }
  }
   
   
 }
 const countryFlagHandler = (countrycode) => {
  if(countrycode!=="Others"){
    if(countrycode.length>2){countrycode = countrycode.slice(0, -1)}
    const select = document.querySelector('#country');
    document.querySelector('#country_img').src = `https://flagpedia.net/data/flags/h80/${countrycode.toLowerCase()}.webp`
  } else {
    document.querySelector('#country_img').src = ''
  }
};

    return (
      <>
        <div id="app" className="app">
          <div id="content" className="app-content">
            <div className='row appTitle mb-3'>
              <div className='col-md-6 mx-auto p-2'>
                <div className='text-center mb-3'>
                  <img src={logo} alt="Logo" style={{width:'40px',marginTop:"-10px"}} />
                  <span style={{fontFamily: "serif", fontSize: "27px", fontWeight:"bold"}}>ZipLink Ai</span>
                </div>
                {/* <h1 className="page-header mb-3">
                    Hello, <small style={{color:'#595959'}}>welcome back.</small>
                </h1> */}
                <small style={{color:'#595959'}}>This App aims to predict startup's possible future status using trained model and a combination of the startups funding and online validity data. <span className='appinstallation'> For easy use you can install <strong>App</strong> on PC or on Mobile phone <button type='button' className='btn btn-secondary btn-sm p-2px' onClick={()=>{installApp()}}>INSTALL APP <i className='fa fa-download'></i></button></span> </small>
              </div>
            </div>
            <div className="row">
              <Profile data={tempmem.data.profile} />
              <Formdata countryFlagHandler={countryFlagHandler} 
              ipLocation={ipLocation} submitForm={SubmitForm} setData={setData} closePreferences={closePreferences} />
              <Donut data={tempmem.data.donutdata} clickInfo={clickInfo} getKeyByValue={getKeyByValue} />
            </div>
            <div className="display_on_reult" style={{display: "none"}}>
                <div className='row'>
                  <Tweet data={tempmem.data.twt_data} />
                  <Ratings data={tempmem.data.ratings} />
                </div>
                <Suggestions data={tempmem.data.recommend} />
                <Analytics data={tempmem.data.keywordsAnalytics} />
                <Barplot data={tempmem.data.bardata} />
                <Qrcode searchkey={tempmem.data.shortLink} profile={tempmem.data.profile} />
                <Footer />
            </div>
          </div>
        </div>
      </>
    );
}

export default Home;