
function Qrcode({searchkey, profile}){
    // build the short link
    try{
        var shortlink = window.location.origin+'/search?q='+searchkey
    } catch(err){
        var shortlink = window.location.origin+'/search?q='
    }

    return(
        <div className='row mb-3'>
        <div className='col-md-3'>
          <div className='card'>
              <div className='card-body text-center'>
              <img src={`https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=${shortlink}&choe=UTF-8`} />
              </div>
          </div>
        </div>
        <div className='col-md-9'>
          <div className='card'>
              <div className='card-body'>
                <h3 className='mb-10px'>Analytics shareable link</h3>
                <p><strong>Name: </strong> {profile ? profile.displayname : ''}</p>
                <p><strong>Search Key: </strong> {searchkey}</p>
                <p><strong>Data Link: </strong> {shortlink}</p>
              </div>
          </div>
        </div>
      </div>
    )
}


export default Qrcode;